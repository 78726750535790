import React from "react";
import "./App.scss";
const Hero = React.lazy(() => import("./hero/Hero"));

const Loading = () => {

  return (
    <div style={{  height: '100%',
    padding: '0',
    margin: '0', display: "flex", alignItems: "center", justifyContent: 'center'}}>
      <div
        style={{
          backgroundColor: "linear-gradient(to right, #0c2839, #1b2342)",
          color: "white",
        }}
      >
        Loading...
      </div>
    </div>
  );
}

function App() {
 

  return (
    <>
    <React.Suspense fallback={<Loading  />}>
      <Hero />
    </React.Suspense>
    </>
  );
}

export default App;
